/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";

const SiteLayoutRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-items: stetch;
  min-height: 100vh;
  min-height: 100dvh;
`;

export default function SiteLayout({ children }) {
  return (
    <SiteLayoutRoot>
      <Header
        css={css`
          width: 100%;
        `}
      />
      <main
        id="main"
        css={css`
          width: 100%;
        `}
      >
        {/* <details>
          <summary>Page context</summary>
          <pre>
            <code>{JSON.stringify(pageContext, null, 2)}</code>
          </pre>
        </details> */}
        {children}
      </main>
      <Footer
        css={css`
          margin-top: var(--spacing-xxl);
          width: 100%;
        `}
      />
    </SiteLayoutRoot>
  );
}
