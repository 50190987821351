/** @jsx jsx */
import isPropValid from "@emotion/is-prop-valid";
import {
  // css,
  jsx,
} from "@emotion/react";
import styled from "@emotion/styled";
import { useComponentWidth } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useMemo } from "react";

import Clickable from "../../../@municipio/gatsby-theme-basic/components/Clickable";

HeaderMenu.propTypes = {};

const HeaderMenuList = styled("ul", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !["overflows"].includes(prop),
})`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.125rem;
  font-weight: 700;
  visibility: ${(props) => (props.overflows ? "hidden" : "visible")};
  overflow: hidden;
`;

const HeaderMenuListItem = styled.li`
  display: flex;
  align-items: center;
  flex: none;
  & > * {
    flex: none;
  }
  &:not(:first-child):before {
    content: "";
    display: block;
    color: #dfdfdf;
    margin: 0 1.5rem;
    width: 0.375rem;
    height: 0.375rem;
    background-color: currentColor;
    border-radius: 50%;
  }
`;

const HeaderMenuLink = styled(Clickable)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

export default function HeaderMenu({ ...restProps }) {
  const { items } = useMenu("HEADER");
  const [width, ref] = useComponentWidth();
  const overflows = useMemo(
    () => ref.current && width < ref.current.scrollWidth,
    [width],
  );

  return (
    <HeaderMenuList ref={ref} overflows={overflows} {...restProps}>
      {items.map((item, index) => (
        <HeaderMenuListItem key={index}>
          <HeaderMenuLink to={item.url}>{item.label}</HeaderMenuLink>
        </HeaderMenuListItem>
      ))}
    </HeaderMenuList>
  );
}
