import ModuleController from "@municipio/gatsby-theme-basic/src/components/ModuleController";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import React from "react";

import PageGrid from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGrid";
import PageGridItem from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGridItem";
import PageGridRow from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGridRow";

export default function ModularityArea({ area = {}, ...restProps }) {
  const { modules } = area;
  if (!modules?.length) {
    return null;
  }
  let moduleRows = modules
    .filter(({ module, hidden }) => module && !hidden)
    .map((module) => ({
      modules: [module],
    }));
  return (
    <modularityAreaContext.Provider value={area}>
      <PageGrid as="div" {...restProps}>
        {moduleRows.map(({ modules }, index) => {
          return (
            <PageGridRow key={index}>
              {modules.map(
                ({ hidden, module, columnWidth, ...rest }, index) => {
                  // const MaybePageGridGroup =
                  //   parseColumnWidth(columnWidth) || area.defaultModuleColSpan
                  //     ? PageGridGroup
                  //     : React.Fragment;
                  return (
                    <modularityModuleContext.Provider
                      value={{ hidden, module, columnWidth, ...rest }}
                      key={index}
                    >
                      <PageGridItem colspan="12">
                        <ModuleController module={module} />
                      </PageGridItem>
                    </modularityModuleContext.Provider>
                  );
                },
              )}
            </PageGridRow>
          );
        })}
      </PageGrid>
    </modularityAreaContext.Provider>
  );
}
