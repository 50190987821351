/** @jsx jsx */
import isPropValid from "@emotion/is-prop-valid";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";

import Button from "../../../../@municipio/gatsby-theme-basic/components/Button";

import Card from "./Card";
import CardContent from "./CardContent";
import CardMedia from "./CardMedia";
import CardMeta from "./CardMeta";
import CardTitle from "./CardTitle";

const Excerpt = styled("p", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "collapsed",
})`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default function WPDefaultContentNodeCard({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  const { date, excerpt, image, showAll, title, url, theme, taxonomies } =
    contentNode;
  return (
    <Card link={{ url }} color={theme} {...restProps}>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        {date && (
          <CardMeta>
            <Time capitalize={true} date={date} format={dateFormat} />
          </CardMeta>
        )}
        {excerpt && <Excerpt collapsed={!showAll}>{excerpt}</Excerpt>}
        {taxonomies && taxonomies.length > 0 && (
          <TermList taxonomies={taxonomies} />
        )}
        <div
          css={css`
            margin-top: 0.5rem;
          `}
        >
          <Button>Läs mer</Button>
        </div>
      </CardContent>
      {image && <CardMedia image={image} />}
    </Card>
  );
}
