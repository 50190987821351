import "./index.css";
import "./colors.props.css";
import "./typography.css";

import memoize from "lodash/memoize";

const minWidthPx = 320,
  maxWidthPx = 1440;

function clampBuilder(minFontSize, maxFontSize) {
  if (minFontSize === maxFontSize) {
    return `${minFontSize}rem`;
  }
  const pixelsPerRem = 16;

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
}

function createTheme({ palette, ...rest }) {
  // let normalizedPalette = {};
  // Object.entries(palette).forEach(([color, value]) => {
  //   if(typeof value === 'string') {
  //     normalizedPalette[color] = {
  //       main: value,
  //     };
  //   }
  // });
  const getColor = memoize((path) => {
    // console.group(path);
    let result = ((path) => {
      let matches;
      matches = path.match(/^palette\.([A-Za-z0-9_-]+)(?:\.(main|\d+))?$/);
      if (matches) {
        let [, color, shade = "main"] = matches;
        // console.log("matches", [color, shade]);
        if (!palette[color]) {
          return null;
        }
        if (typeof palette[color] === "string") {
          if (palette[color].startsWith("palette.")) {
            // {primary: 'palette.blue'}
            // {primary: 'palette.blue.main'}
            // {primary: 'palette.blue.500'}
            let parts = palette[color].split(".");
            parts = Object.assign(["palette", color, shade], parts);
            return getColor(parts.join("."));
          }
          // {primary: '#123'}
          // TODO: Handle shade
          return palette[color];
        }
        if (shade === "main" && !palette[color].main) {
          shade = 500;
        }
        if (palette[color][shade].startsWith("palette.")) {
          // {primary: {main: 'palette.blue'}}
          // {primary: {main: 'palette.blue.main'}}
          // {primary: {500: 'palette.blue.500'}}
          return getColor(palette[color][shade]);
        }
        // {primary: {main: '#123'}}
        // {primary: {500: '#123'}}
        return palette[color][shade];
      }
    })(path);
    // console.log({ result });
    // console.groupEnd();
    return result;
  });

  const theme = {
    ...rest,
  };

  theme.palette = (path) => getColor(`palette.${path}`);
  Object.assign(theme.palette, palette);

  return theme;
}

export default createTheme({
  palette: {
    primary: "palette.blue",
    blue: {
      50: "#ebeff5",
      100: "#d5deeb",
      200: "#abbdd7",
      300: "#829bc2",
      400: "#587aae",
      500: "#2e599a",
      600: "#234374",
      700: "#172d4d",
      800: "#0c1627",
      900: "#05090f",
    },
  },
  typography: {
    xxl: {
      fontSize: clampBuilder(30 / 16, 54 / 16),
    },
    xl: {
      fontSize: clampBuilder(26 / 16, 40 / 16),
    },
    lg: {
      fontSize: clampBuilder(22 / 16, 26 / 16),
    },
    md: {
      fontSize: clampBuilder(18 / 16, 20 / 16),
    },
    sm: {
      fontSize: clampBuilder(16 / 16, 16 / 16),
    },
    xs: {
      fontSize: clampBuilder(14 / 16, 14 / 16),
    },
    xxs: {
      fontSize: clampBuilder(12 / 16, 12 / 16),
    },
  },
  spacing: {
    xxl: clampBuilder(48 / 16, 64 / 16),
    xl: clampBuilder(36 / 16, 48 / 16),
    lg: clampBuilder(24 / 16, 32 / 16),
    md: clampBuilder(18 / 16, 24 / 16),
    sm: clampBuilder(12 / 16, 16 / 16),
  },
});
