/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { H, Section } from "@jfrk/react-heading-levels";
import {
  SEO,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
// import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

// import { Fragment } from "react";
import EventHeading from "../components/EventHeading";
import PageContent from "../components/PageContent";
import PageContentAreaModules from "../components/PageContentAreaModules";
import PageFeaturedImage from "../components/PageFeaturedImage";
import PageGrid from "../components/PageGrid";
import PageGridItem from "../components/PageGridItem";
import PageGridRow from "../components/PageGridRow";
import PagePreamble from "../components/PagePreamble";
const EventOccasionList = styled.ul`
  display: grid;
  gap: 0.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const EventOccasionItem = styled.li`
  padding: 0;
`;

export default function EventTemplate() {
  const { title, eventAttributes, eventOccasions } = usePageContext();
  console.log(eventOccasions);
  const prices = eventAttributes?.prices?.split(/\n/g);
  return (
    <article>
      <SEO title={title} />
      <PageFeaturedImage />
      {/* {!isFrontPage && <PageBreadcrumbs />} */}
      <PageGrid
        css={css`
          padding: 0 var(--spacing-lg);
          margin-bottom: 2.5rem;
          padding-bottom: 4rem;
          border-bottom: 1px solid #ddddda;
        `}
      >
        <PageGridRow>
          <PageGridItem colspan="12">
            <EventHeading
              css={css`
                margin-bottom: 4rem;
              `}
            />
          </PageGridItem>
        </PageGridRow>
        <PageGridRow>
          <PageGridItem colspan="3">
            <H
              css={css`
                margin-bottom: 0.5rem;
              `}
            >
              Tillfällen
            </H>
            {eventOccasions?.length ? (
              <EventOccasionList>
                {eventOccasions.map((occasion, index) => (
                  <EventOccasionItem key={index}>
                    <Time
                      format={{
                        weekday: "long",
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        // timeZone: "Europe/Stockholm",
                      }}
                      date={`${occasion.date}T${occasion.startTime}`}
                      capitalize
                    />{" "}
                    <Time
                      format={{
                        timeStyle: "short",
                        // timeZone: "Europe/Stockholm",
                      }}
                      date={`${occasion.date}T${occasion.startTime}`}
                    />
                    {"–"}
                    <Time
                      format={{
                        timeStyle: "short",
                        // timeZone: "Europe/Stockholm",
                      }}
                      date={`${occasion.date}T${occasion.endTime}`}
                    />
                  </EventOccasionItem>
                ))}
              </EventOccasionList>
            ) : (
              <i>Inga tillfällen är planerade.</i>
            )}
          </PageGridItem>
          <PageGridItem colspan="3">
            <H
              css={css`
                margin-bottom: 0.5rem;
              `}
            >
              Priser
            </H>
            {prices?.length ? (
              <EventOccasionList>
                {prices.map((price, index) => (
                  <EventOccasionItem key={index}>{price}</EventOccasionItem>
                ))}
              </EventOccasionList>
            ) : (
              <i>Ingen prisinformation tillgänglig.</i>
            )}
          </PageGridItem>
        </PageGridRow>
      </PageGrid>
      <Section>
        <PageGrid
          css={css`
            padding: 0 var(--spacing-lg);
          `}
        >
          <PageGridItem>
            <PagePreamble
              css={css`
                margin-bottom: 2.5rem;
                font-weight: 600;
              `}
            />
            <PageContent />
          </PageGridItem>
        </PageGrid>
        <PageContentAreaModules
          css={css`
            padding: 0 var(--spacing-lg);
          `}
        />
      </Section>
    </article>
  );
}
