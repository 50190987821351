import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import React from "react";

import Clickable from "../../../../@municipio/gatsby-theme-basic/components/Clickable";

const CardRoot = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "color",
})`
  position: relative;
  background: ${({ theme, color }) => theme.palette(`${color}.50`) || "white"};
  color: inherit;
  padding: var(--card-padding);

  &:hover,
  &:focus-within {
    background: ${({ theme, color }) =>
      theme.palette(`${color}.100`) || "white"};
  }
`;

const CardLink = styled(Clickable)`
  overflow: hidden;
  display: grid;
  align-items: stretch;
  align-self: stretch;
  position: relative;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%, var(--card-column-min-width)), 1fr)
  );
  grid-auto-rows: var(--card-grid-auto-rows);
  height: 100%;
  color: inherit;
  text-decoration: none;

  &:hover {
  }
`;

export default function Card({ children, link = {}, ...restProps }) {
  return (
    <CardRoot {...restProps}>
      <CardLink {...link}>{children}</CardLink>
    </CardRoot>
  );
}
