/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { H } from "@jfrk/react-heading-levels";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

import usePageContent from "../hooks/usePageContent";

const EventHeadingRoot = styled.div``;

export default function EventHeading({ ...restProps }) {
  const pageContext = usePageContext();
  const title = pageContext.title;
  const heading = usePageContent().heading;
  // const address = pageContext.eventAttributes?.address;

  return (
    <EventHeadingRoot {...restProps}>
      <TextContent
        css={css`
          text-align: center;
        `}
      >
        {heading || <H>{title}</H>}
      </TextContent>
    </EventHeadingRoot>
  );
}
