import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Clickable from "../../../@municipio/gatsby-theme-basic/components/Clickable";
import logo from "../../../images/logo.svg";

export default function HeaderLogo({ ...restProps }) {
  let title = useStaticQuery(graphql`
    query SiteTitleQuery_Shadow {
      site {
        siteMetadata {
          title
        }
      }
    }
  `).site?.siteMetadata?.title;
  return (
    <Clickable {...restProps}>
      <img src={logo} alt={title} />
    </Clickable>
  );
}
