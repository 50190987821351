import styled from "@emotion/styled";
import { H } from "@jfrk/react-heading-levels";

const CardTitle = styled(H)`
  color: ${({ theme }) => theme.palette("primary.800")};
  font-size: var(--typography-lg-font-size);
  font-weight: 700;
  line-height: var(--typography-lg-line-height);
  letter-spacing: var(--typography-lg-letter-spacing);
  /* text-decoration: var(--card-title-text-decoration, inherit); */
  margin: 0;
`;

export default CardTitle;
