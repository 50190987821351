/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { H } from "@jfrk/react-heading-levels";
import { Icon } from "@whitespace/components";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { Fragment } from "react";

import Link from "../../../@municipio/gatsby-theme-basic/components/Link";
import mapExample from "../../../images/map-example.png";
import usePageContent from "../hooks/usePageContent";

const LocationHeadingRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  gap: var(--page-grid-gap);
  padding-bottom: 4rem;
  border-bottom: 1px solid #ddddda;
`;

const Address = styled("address")`
  font-style: inherit;
  font-weight: 700;
  color: #666;
`;

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: ${({ theme }) => theme.palette("primary.main")};
  --icon-size: 2.5rem;
`;

export default function LocationHeading({ ...restProps }) {
  const pageContext = usePageContext();
  const title = pageContext.title;
  const heading = usePageContent().heading;
  const address = pageContext.locationAttributes?.address;

  return (
    <LocationHeadingRoot {...restProps}>
      <div
        css={css`
          display: grid;
          gap: 1rem;
        `}
      >
        <div>
          <div
            css={css`
              &:after {
                content: "";
                display: table;
                margin-top: 5rem;
                margin-bottom: -5rem;
              }
            `}
          >
            <TextContent {...restProps}>
              {heading || <H>{title}</H>}
            </TextContent>
          </div>
          {!!address && (
            <Fragment>
              <Address
                css={css`
                  margin-top: 0.5rem;
                  display: block;
                `}
              >
                {address.replace(/\n/g, ", ")}
              </Address>
              <p
                css={css`
                  margin-top: 0.5rem;
                  margin-bottom: 0;
                `}
              >
                <Link to="https://example.com">
                  <Icon name="directions" /> Vägbeskrivning
                </Link>
              </p>
            </Fragment>
          )}
        </div>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 1rem 2.5rem;
          `}
        >
          <div>
            <b>Webbplats</b>
            <br />
            <Link href="https://example.com">example.com</Link>
          </div>
          <div>
            <b>Telefon</b>
            <br />
            <Link href="tel:086785500">08-678 55 00</Link>
          </div>
          <div>
            <b>Avstånd från centrum</b>
            <br />
            200 meter
          </div>
        </div>
        <IconContainer>
          <Icon name="instagram-round" />
          <Icon name="facebook-round" />
        </IconContainer>
      </div>
      <div>
        <img src={mapExample} alt="Karta" />
      </div>
    </LocationHeadingRoot>
  );
}
