import Grid from "@municipio/gatsby-theme-basic/src/components/Grid";
import PostsModuleFilterForm from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterForm";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PostsModuleHeader from "@municipio/gatsby-theme-basic/src/components/PostsModuleHeader";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import React from "react";

export default function PostsModuleGridLayout({
  itemComponent: Item,
  title,
  module,
  normalizedItems,
  ...restProps
}) {
  const {
    modPostsDataDisplay: { theme },
  } = module;

  const { schema } = useSearch();

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      components={{
        ModuleWrapperHeader: PostsModuleHeader,
      }}
    >
      <PostsModuleFilterForm />
      <Grid autoFit={!schema}>
        {normalizedItems.map((item, index) => {
          return <Item key={index} item={{ theme, ...item }} />;
        })}
      </Grid>
    </ModuleWrapper>
  );
}
