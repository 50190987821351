/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import styled from "@emotion/styled";

const PageGridRoot = styled.div`
  --page-grid-item-min-width: 18rem;
  --page-grid-wrapper-max-width: 80rem;
  --page-grid-gap: var(--spacing-md);

  --grid-column-gap: var(--page-grid-gap);

  display: grid;
  box-sizing: content-box;
  justify-items: center;
  max-width: var(--page-grid-wrapper-max-width);
  margin: auto;
  gap: var(--page-grid-gap);
  /* background: repeating-linear-gradient(
    to right,
    #00000008,
    #00000008
      calc(1 / 12 * (100% + var(--page-grid-gap)) - var(--page-grid-gap)),
    #00000000
      calc(1 / 12 * (100% + var(--page-grid-gap)) - var(--page-grid-gap)),
    #00000000 calc(1 / 12 * (100% + var(--page-grid-gap)))
  ); */

  --page-grid-item-max-width: calc(
    8 / 12 * (var(--page-grid-wrapper-max-width) + var(--page-grid-gap)) -
      var(--page-grid-gap)
  );
  --page-grid-item-width: 100%;
`;

export default function PageGrid({ children, ...restProps }) {
  return <PageGridRoot {...restProps}>{children}</PageGridRoot>;
}
