import styled from "@emotion/styled";

import Clickable from "./Clickable";

const Button = styled(Clickable)`
  display: inline-flex;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.palette("primary.500")};
  color: white;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
`;

export default Button;
