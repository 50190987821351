/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
// import { Fragment } from "react";

import usePageContent from "../hooks/usePageContent";

export default function PagePreamble({ ...restProps }) {
  const { preamble } = usePageContent();

  if (!preamble) {
    return null;
  }

  return (
    <TextContent
      {...restProps}
      css={css`
        --typography-font-size: 1.25rem;
        --typography-line-height: 2.125rem;
        opacity: 0.8;
        letter-spacing: 0.02em;
      `}
    >
      {preamble}
    </TextContent>
  );
}
