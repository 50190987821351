/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import styled from "@emotion/styled";
import { SkipTo } from "@whitespace/components";
import HeaderFlyOutMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderFlyOutMenu";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
// import { Fragment } from 'react';

import HeaderMenu from "./HeaderMenu";

const HeaderRoot = styled.header`
  height: 6rem;
  padding: 1rem var(--spacing-lg);
  box-sizing: border-box;
  --page-grid-wrapper-max-width: 80rem;
  margin: 0 auto;

  max-width: var(--page-grid-wrapper-max-width);
`;

const Slinky = styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) minmax(0, max-content) minmax(
      max-content,
      1fr
    );
  gap: 2rem;
  height: 100%;
  & > :last-child {
    justify-self: end;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export default function Header({ ...rest }) {
  return (
    <HeaderRoot {...rest}>
      <SkipTo />
      <Slinky>
        <div>
          <HeaderLogo to="/" />
        </div>
        <HeaderMenu />
        <HeaderRight>
          <HeaderFlyOutMenu />
        </HeaderRight>
      </Slinky>
    </HeaderRoot>
  );
}
