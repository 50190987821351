/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import { Link } from "gatsby";

import useClickable from "../hooks/useClickable";

function InternalLink({ href, ...rest }) {
  return <Link to={href} {...rest} />;
}

export default function Clickable(inProps) {
  const { componentType, props } = useClickable(inProps);

  let Component =
    (componentType === "inert" && "span") ||
    (componentType === "label" && "label") ||
    (componentType === "submit" && "button") ||
    (componentType === "reset" && "button") ||
    (componentType === "button" && "button") ||
    (componentType === "internal" && InternalLink) ||
    "a";

  return <Component {...props} />;
}
