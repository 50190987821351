import styled from "@emotion/styled";

const CardMeta = styled("div")`
  font-size: var(--card-meta-font-size);
  color: var(--card-meta-color);
  font-weight: var(--card-meta-font-weight);
  line-height: var(--card-meta-line-height);
  letter-spacing: var(--card-meta-letter-spacing);
`;

export default CardMeta;
