import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageContent } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

export default function ArticleBody({ ...restProps }) {
  let {
    // blocksJSON,
    content: contentHTML,
    contentArea,
    contentMedia,
    contentModularityModules,
  } = usePageContext();

  return (
    <TextContent {...restProps}>
      <PageContent
        input={contentHTML}
        contentMedia={contentMedia}
        contentModularityModules={contentModularityModules?.nodes}
      >
        {({ preamble, content }) => (
          <>
            {preamble && <div>{preamble}</div>}
            {content}
            <ModularityArea area={contentArea} />
          </>
        )}
      </PageContent>
    </TextContent>
  );
}
