import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

const PageGridRow = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && !["colspan"].includes(prop),
})`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  gap: var(--page-grid-gap);
  width: 100%;
  --page-grid-item-width: calc(
    var(--page-grid-columns) / 12 * (100% + var(--page-grid-gap)) -
      var(--page-grid-gap)
  );
  --page-grid-item-max-width: 100%;
`;

export default PageGridRow;
