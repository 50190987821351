import styled from "@emotion/styled";
import * as defaultStyles from "@municipio/gatsby-theme-basic/src/components/modularity-modules/MenuModule.module.css";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { RoundIcon } from "@whitespace/components";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import React from "react";

import Clickable from "../Clickable";

function defaultTransformMenuItem(menuItem, { pages }) {
  let { connectedNode, label, description, url, target, extra, ...rest } =
    menuItem;

  let icon = extra?.icon || rest?.icon;
  if (typeof icon === "string") {
    icon = { name: icon };
  }

  let { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
    connectedNode?.node || {};

  let content = type === "page" ? getPage(pages, id) : {};

  return {
    type,
    url,
    target: connectedNode?.node?.id ? target : "_blank",
    ...content,
    label,
    description: description || (content && content.description),
    ...extra,
    ...rest,
    icon,
  };
}

const Menu = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: var(--page-grid-gap);
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(0, 9.5rem));
`;

const MenuItem = styled("li")`
  padding: 0;
`;

const MenuLink = styled(Clickable)`
  display: grid;
  gap: 1rem;
  text-align: center;
  justify-items: center;
  padding: 1.5rem 0.5rem;
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.125;
  background-color: ${({ theme }) => theme.palette("primary.50")};
  --round-icon-background-color: ${({ theme }) => theme.palette("primary")};
  --icon-color: white;
  --round-icon-inner-size: 70.71%; // 1/sqrt(2)
  &:hover {
    background-color: ${({ theme }) => theme.palette("primary.100")};
  }
`;

export default function MenuModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  transformMenuItem = defaultTransformMenuItem,
  ...restProps
}) {
  const pages = usePages();
  const { menu } = module;

  // const theme = useTheme();

  let menuItems = menu?.node?.menuItems?.nodes?.map((node) =>
    transformMenuItem(node, { pages }),
  );

  if (!menuItems?.length) {
    return null;
  }

  // console.log(menuItems);

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <Menu>
        {menuItems.map((item, index) => (
          <MenuItem key={index}>
            <MenuLink to={item.url}>
              <RoundIcon {...item.icon} size="4rem" />
              {item.label}
            </MenuLink>
          </MenuItem>
        ))}
      </Menu>
    </ModuleWrapper>
  );
}
