import styled from "@emotion/styled";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import React from "react";

const CardImage = styled(Image)`
  order: -1;
  width: 100%;
  height: 100%;
`;

const Dummy = styled.div`
  grid-column: 1;
`;

export default function CardMedia({ aspectRatio = 16 / 9, image }) {
  if (!image) {
    return null;
  }
  return (
    <>
      <CardImage
        {...image}
        caption={null}
        credit={null}
        aspectRatio={aspectRatio}
      />
      <Dummy />
    </>
  );
}
