/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Icon } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

import Clickable from "../../../@municipio/gatsby-theme-basic/components/Clickable";
import IconLink from "../../../@municipio/gatsby-theme-basic/components/IconLink";
import logo from "../../../images/logo-inverted.svg";

import PageGrid from "./PageGrid";
import PageGridItem from "./PageGridItem";
import PageGridRow from "./PageGridRow";

const FooterRoot = styled.footer`
  background-color: ${({ theme }) => theme.palette("primary.700")};
  color: white;
  --icon-color: ${({ theme }) => theme.palette("primary.200")};
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const FooterMenu = styled("div")``;
const FooterMenuList = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 0rem;
`;
const FooterBottomMenuList = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
const FooterMenuItem = styled("li")`
  padding: 0;
`;
const FooterMenuItemLink = styled(IconLink)`
  text-decoration: none;
  color: inherit;
  line-height: 1.125;
  padding: 0.5rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const FooterBottom = styled("div")`
  padding: 1rem 0;
  margin: 0 var(--spacing-lg);
  border-top: 1px solid ${({ theme }) => theme.palette("primary.600")};
`;

export default function Footer({ ...restProps }) {
  const { items: footerMenuItems } = useMenu("FOOTER") || {};
  const { items: bottomMenuItems } = useMenu("BOTTOM") || {};
  return (
    <FooterRoot {...restProps}>
      <PageGrid
        css={css`
          padding: 7rem var(--spacing-lg);
        `}
      >
        <PageGridRow>
          <PageGridItem colspan="3">
            <img src={logo} alt="" />
          </PageGridItem>
          {!!footerMenuItems?.length && (
            <PageGridItem colspan="3">
              <FooterMenu>
                <FooterMenuList>
                  {footerMenuItems.map((item, index) => (
                    <FooterMenuItem key={index}>
                      <FooterMenuItemLink to={item.url} icon={item.icon}>
                        {item.label}
                      </FooterMenuItemLink>
                    </FooterMenuItem>
                  ))}
                </FooterMenuList>
              </FooterMenu>
            </PageGridItem>
          )}
          <PageGridItem colspan="3">
            Detta är en demowebbplats av Whitespace.
            <br />
          </PageGridItem>
          <PageGridItem colspan="3">
            <FooterMenu>
              <FooterMenuList>
                <FooterMenuItem>
                  <FooterMenuItemLink to="https://example.com">
                    <Icon name="instagram-round" size="2.5rem" />
                    Instagram
                  </FooterMenuItemLink>
                </FooterMenuItem>
                <FooterMenuItem>
                  <FooterMenuItemLink to="https://example.com">
                    <Icon name="facebook-round" size="2.5rem" />
                    Facebook
                  </FooterMenuItemLink>
                </FooterMenuItem>
              </FooterMenuList>
            </FooterMenu>
          </PageGridItem>
        </PageGridRow>
      </PageGrid>
      <FooterBottom
        css={css`
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
        `}
      >
        <div
          css={css`
            font-weight: 400;
          `}
        >
          {!!bottomMenuItems?.length && (
            <FooterBottomMenuList>
              {bottomMenuItems.map((item, index) => (
                <FooterMenuItem key={index}>
                  <FooterMenuItemLink to={item.url} icon={item.icon}>
                    {item.label}
                  </FooterMenuItemLink>
                </FooterMenuItem>
              ))}
            </FooterBottomMenuList>
          )}
        </div>
        <Clickable
          to="https://whitespace.se"
          css={css`
            color: inherit;
            text-decoration: none;
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          `}
        >
          Till Whitespace webbplats →
        </Clickable>
      </FooterBottom>
    </FooterRoot>
  );
}
