import { useURLTransformer } from "@whitespace/components";

function getURLType(url) {
  if (url.startsWith("#")) {
    return "anchor";
  }
  try {
    let { protocol, hostname } = new URL(url);
    if (protocol && protocol !== "http:" && protocol !== "https:") {
      return protocol.substr(0, protocol.length - 1);
    }
    if (hostname) {
      return "external";
    }
  } catch {
    // Continue
  }
  return "internal";
}

export default function useClickable({
  href,
  htmlFor,
  onClick,
  rel,
  target,
  to,
  type,
  uri,
  url,
  ...rest
}) {
  href =
    href != null && href !== ""
      ? href
      : to != null && to !== ""
      ? to
      : url != null && url !== ""
      ? url
      : uri != null && uri !== ""
      ? uri
      : null;

  const transformURL = useURLTransformer();

  href = transformURL(href);

  let componentType =
    type ||
    (htmlFor
      ? "label"
      : href != null
      ? getURLType(href)
      : onClick
      ? "button"
      : "inert");

  if (componentType === "button") {
    type = "button";
  }

  /**
   * Add rel="noopener noreferrer" to links with target="_blank"
   */
  if (target === "_blank") {
    rel = [
      ...new Set([...(rel ? rel.split(/\s+/g) : []), "noopener", "noreferrer"]),
    ].join(" ");
  }

  const props = {
    href,
    htmlFor,
    onClick,
    rel,
    target,
    type,
    ...rest,
  };

  return { componentType, props };
}
