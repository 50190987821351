/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import styled from "@emotion/styled";
import { useLocation } from "@gatsbyjs/reach-router";
import { Icon } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { Fragment, useEffect, useState } from "react";

import Clickable from "../../../@municipio/gatsby-theme-basic/components/Clickable";

const MenuButton = styled(Clickable)`
  display: inline-flex;
  align-items: center;
  border: 1px solid #ddddda;
  background: none;
  box-shadow: none;
  padding: 0.75rem;
  cursor: pointer;
  font: inherit;
  gap: 0.5rem;
  line-height: 1;
  font-size: 0.857rem;
`;

const Backdrop = styled("div")`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0004;
  z-index: 1;
`;
const Menu = styled("div")`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 2;
  background: white;
  min-width: 12rem;
  max-width: calc(100dvw - 2rem);
  padding-top: 2rem;
`;
const MenuList = styled("ul")`
  list-style: none;
  padding: 0.5rem 1rem;
  margin: 0;
  display: grid;
  gap: 1rem;
`;
const MenuItem = styled("li")`
  padding: 0;
`;
const MenuItemLink = styled(Clickable)`
  display: block;
  text-decoration: none;
  color: inherit;
  line-height: 1.25;
  padding: 0.5rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export default function HeaderFlyOutMenu({ ...restProps }) {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { items } = useMenu("SIDEBAR");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  if (!items?.length) {
    return null;
  }

  return (
    <div {...restProps}>
      <MenuButton type="button" onClick={() => setExpanded(!expanded)}>
        <Icon name="menu" size="1rem" />
        Meny
      </MenuButton>
      {expanded && (
        <Fragment>
          <Backdrop onClick={() => setExpanded(false)} />
          <Menu>
            <MenuList>
              {items.map((item, index) => (
                <MenuItem key={index}>
                  <MenuItemLink to={item.url}>{item.label}</MenuItemLink>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </div>
  );
}
