import styled from "@emotion/styled";

import Clickable from "./Clickable";

const Link = styled(Clickable)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette("primary.500")};
`;

export default Link;
