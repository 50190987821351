/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Icon } from "@whitespace/components";

import Clickable from "./Clickable";

export default function IconLink({ icon, children, ...rest }) {
  return (
    <Clickable
      css={css`
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
      `}
      {...rest}
    >
      {!!icon && <Icon {...icon} />}
      {children}
    </Clickable>
  );
}
