import styled from "@emotion/styled";

const CardContent = styled("div")`
  display: grid;
  padding: var(--card-content-padding);
  gap: var(--card-content-gap);
  align-content: var(--card-content-align-content);
  line-height: 1.75;
`;

export default CardContent;
