/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Section } from "@jfrk/react-heading-levels";
import { SEO } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
// import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

// import { Fragment } from "react";
import PageContent from "../components/PageContent";
import PageContentAreaModules from "../components/PageContentAreaModules";
import PageFeaturedImage from "../components/PageFeaturedImage";
import PageGrid from "../components/PageGrid";
import PageGridItem from "../components/PageGridItem";
import PageGridRow from "../components/PageGridRow";
import PageHeading from "../components/PageHeading";
import PagePreamble from "../components/PagePreamble";

export default function DefaultPageTemplate() {
  const { title, isFrontPage } = usePageContext();
  return (
    <article>
      <SEO title={title} isFrontPage={isFrontPage} />
      <PageFeaturedImage />
      {/* {!isFrontPage && <PageBreadcrumbs />} */}
      <PageGrid
        css={css`
          padding: 0 var(--spacing-lg);
        `}
      >
        <PageGridRow>
          <PageGridItem colspan="12">
            <PageHeading
              css={css`
                text-align: center;
              `}
            />
          </PageGridItem>
        </PageGridRow>
      </PageGrid>
      <Section>
        <PageGrid
          css={css`
            padding: 0 var(--spacing-lg);
          `}
        >
          <PageGridItem>
            <PagePreamble
              css={css`
                text-align: center;
                margin-bottom: 2.5rem;
              `}
            />
            <PageContent />
          </PageGridItem>
        </PageGrid>
        <PageContentAreaModules
          css={css`
            padding: 0 var(--spacing-lg);
          `}
        />
      </Section>
    </article>
  );
}
